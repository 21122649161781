<template>
  <el-dialog :append-to-body="true" :visible.sync="dialog" :title="editable ? '调价申请' : '价格策略明细'" @closed="cancel" :close-on-click-modal="false" width="960px">
    <el-tabs v-model="currentTab" v-if="form">
      <el-tab-pane label="批发价" name="basic">
        <template v-if="editable">
          <el-form ref="basicForm" :model="form" :rules="rules" label-position="right">
            <el-form-item prop="basicPrices" class="gap-1x">
              <el-button size="mini" type="info" icon="el-icon-plus" @click.stop="handleBasicAdd">添加价格</el-button>
              <el-table ref="basic" :data="form.basicPrices" size="mini" empty-text="还没有批发价的调价申请">
                <el-table-column label="起订量" width="120">
                  <template slot-scope="scope">
                    <el-input-number v-model="scope.row.modMin" :min="0" :step="1" :precision="0" size="small" controls-position="right" style="width: 100%;" />
                  </template>
                </el-table-column>
                <el-table-column label="价格" width="150">
                  <template slot-scope="scope">
                    <price-input :min="0" v-model="scope.row.goodsPrice" size="small" />
                  </template>
                </el-table-column>
                <el-table-column label="日期" min-width="200">
                  <template slot-scope="scope">
                    <el-date-picker type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="timestamp" format="yyyy/MM/dd" v-model="scope.row._dateRange" :default-time="['00:00:00', '23:59:59']" style="width: 100%;" />
                  </template>
                </el-table-column>
                <el-table-column fixed="right" width="40">
                  <template slot-scope="scope">
                    <el-button size="mini" type="text" icon="el-icon-delete" @click="remove(scope.row,0)" />
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>
            <div class="dialog-footer h c" v-if="editable">
              <div class="flex"></div>
              <el-button type="text" @click="dialog=false">取消</el-button>
              <el-button :loading="saving" type="primary" @click="handleSave">保存</el-button>
            </div>
          </el-form>
        </template>
        <template v-else>
          <el-table ref="basic" :data="form.basicPrices" empty-text="没有批发价的价格策略">
            <el-table-column prop="modMin" label="起订量" width="120" />
            <el-table-column label="价格" width="150" :formatter="r => {return '￥' + (r.goodsPrice / 100).toFixed(2);}" />
            <el-table-column label="日期" min-width="200">
              <template slot-scope="scope">{{new Date(scope.row.beginTime).format("yyyy/MM/dd")}}至{{new Date(scope.row.endTime).format("yyyy/MM/dd")}}</template>
            </el-table-column>
          </el-table>
        </template>
      </el-tab-pane>
      <el-tab-pane label="经销商类型" name="second">
        <template v-if="editable">
          <el-form ref="typeForm" :model="form" :rules="rules" label-position="right">
            <el-form-item prop="typePrices" class="gap-1x">
              <datagrid-picker reference="选择经销商类型" reference-type="info" url="api/distributorLeve" :popper-width="400" @change="handleTypeAdd">
                <el-table-column prop="name" label="经销商类型" min-width="160" />
              </datagrid-picker>

              <el-table ref="typePrices" :data="form.typePrices" size="mini" empty-text="还没有针对经销商类型的调价申请">
                <el-table-column label="经销商类型" prop="distributorLeveName" min-width="160" />
                <el-table-column label="起订量" width="120">
                  <template slot-scope="scope">
                    <el-input-number v-model="scope.row.modMin" :min="0" :step="1" :precision="0" size="small" controls-position="right" style="width: 100%;" />
                  </template>
                </el-table-column>
                <el-table-column label="价格" width="150">
                  <template slot-scope="scope">
                    <price-input :min="0" v-model="scope.row.goodsPrice" size="small" />
                  </template>
                </el-table-column>
                <el-table-column label="日期" min-width="200">
                  <template slot-scope="scope">
                    <el-date-picker type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="timestamp" format="yyyy/MM/dd" v-model="scope.row._dateRange" :default-time="['00:00:00', '23:59:59']" style="width: 100%;" />
                  </template>
                </el-table-column>
                <el-table-column fixed="right" width="40">
                  <template slot-scope="scope">
                    <el-button size="mini" type="text" icon="el-icon-delete" @click="remove(scope.row,1)" />
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>
            <div class="dialog-footer h c" v-if="editable">
              <div class="flex"></div>
              <el-button type="text" @click="dialog=false">取消</el-button>
              <el-button :loading="saving" type="primary" @click="handleSave">保存</el-button>
            </div>
          </el-form>
        </template>
        <template v-else>
          <el-table ref="basic" :data="form.typePrices" empty-text="没有针对经销商类型的价格策略">
            <el-table-column label="经销商类型" prop="distributorLeveName" min-width="160" />
            <el-table-column prop="modMin" label="起订量" width="120" />
            <el-table-column label="价格" width="150" :formatter="r => {return '￥' + (r.goodsPrice / 100).toFixed(2);}" />
            <el-table-column label="日期" min-width="200">
              <template slot-scope="scope">{{new Date(scope.row.beginTime).format("yyyy/MM/dd")}}至{{new Date(scope.row.endTime).format("yyyy/MM/dd")}}</template>
            </el-table-column>
          </el-table>
        </template>
      </el-tab-pane>
      <el-tab-pane label="指定经销商" name="third">
        <template v-if="editable">
          <el-form ref="singleForm" :model="form" :rules="rules" label-position="right">
            <el-form-item prop="singlePrices" class="gap-1x">
              <datagrid-picker reference="选择经销商" reference-type="info" url="api/distributor" :query-define="dis.queryDefine" :popper-width="800" @change="handleDisAdd">
                <div class="padding-10 bc-l" slot="banner" slot-scope="scope">
                  <el-input :maxlength="20" v-model="dis.queryDefine.name" @keypress.enter.native="scope.query" @clear="scope.query" :validate-event="false" clearable placeholder="输入经销商名称进行搜索" style="width: 240px;">
                    <el-button icon="el-icon-search" slot="append" @click.stop="scope.query" />
                  </el-input>
                </div>
                <el-table-column prop="name" label="经销商" min-width="160" />
                <el-table-column label="经销商类型" width="160" :formatter="r => {return getName(r.level);}" />
                <el-table-column label="所在地区" :formatter="v=>{return v.provinceName+'/'+v.cityName + '/' + v.districtName}" />
              </datagrid-picker>
              <el-table v-loading="loading" :data="form.singlePrices" size="small" style="width: 100%;" empty-text="还没有针对指定经销商的调价申请">
                <el-table-column label="经销商" prop="distributorName" min-width="160" show-overflow-tooltip />
                <el-table-column label="起订量" width="120">
                  <template slot-scope="scope">
                    <el-input-number v-model="scope.row.modMin" :min="0" :step="1" :precision="0" size="small" controls-position="right" style="width: 100%;" />
                  </template>
                </el-table-column>
                <el-table-column label="价格" width="150">
                  <template slot-scope="scope">
                    <price-input :min="0" v-model="scope.row.goodsPrice" size="small" />
                  </template>
                </el-table-column>
                <el-table-column label="日期" min-width="200">
                  <template slot-scope="scope">
                    <el-date-picker type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="timestamp" format="yyyy/MM/dd" v-model="scope.row._dateRange" :default-time="['00:00:00', '23:59:59']" style="width: 100%;" />
                  </template>
                </el-table-column>
                <el-table-column fixed="right" width="40">
                  <template slot-scope="scope">
                    <el-button size="mini" type="text" icon="el-icon-delete" @click="remove(scope.row,2)" />
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>
            <div class="dialog-footer h c" v-if="editable">
              <div class="flex" />
              <el-button type="text" @click="dialog=false">取消</el-button>
              <el-button :loading="saving" type="primary" @click="handleSave">保存</el-button>
            </div>
          </el-form>
        </template>
        <template v-else>
          <el-table ref="basic" :data="form.singlePrices" empty-text="没有针对指定经销商的价格策略">
            <el-table-column label="经销商" prop="distributorName" min-width="160" />
            <el-table-column prop="modMin" label="起订量" width="120" />
            <el-table-column label="价格" width="150" :formatter="r => {return '￥' + (r.goodsPrice / 100).toFixed(2);}" />
            <el-table-column label="日期" min-width="200">
              <template slot-scope="scope">{{new Date(scope.row.beginTime).format("yyyy/MM/dd")}}至{{new Date(scope.row.endTime).format("yyyy/MM/dd")}}</template>
            </el-table-column>
          </el-table>
        </template>
      </el-tab-pane>
    </el-tabs>
  </el-dialog>
</template>
<script>
import { add } from "@/api/adjust";
import request from "@/utils/request";

export default {
  data() {
    const validateInfo = (rule, value, callback) => {
      var arr = [],
        err = "";
      for (let item of value) {
        if (!item.modMin) {
          err = "起订量不能为空!";
          break;
        }
        if (
          !item._dateRange ||
          (item._dateRange && item._dateRange.length < 2)
        ) {
          err = "日期不能为空!";
          break;
        }
      }
      if (err) {
        callback(new Error(err));
      } else {
        callback();
      }
    };

    return {
      currentTab: "basic",
      dialog: false,
      editable: false,
      saving: false,
      loading: false,
      priceSaving: false,
      dis: {
        queryDefine: {}
      },
      form: {
        priceType: 0,
        goodsId: "",
        goodsPrice: null,
        beginTime: "",
        endTime: "",
        _dateRange: [],
        basicPrices: [],
        typePrices: [],
        singlePrices: []
      },
      disLevel: [],
      rules: {
        basicPrices: [
          //{ required: true, message: "价格信息为必填项" },
          { validator: validateInfo, trigger: "blur" }
        ],
        typePrices: [
          //{ required: true, message: "价格信息为必填项" },
          { validator: validateInfo, trigger: "blur" }
        ],
        singlePrices: [
          //{ required: true, message: "价格信息为必填项" },
          { validator: validateInfo, trigger: "blur" }
        ]
      },
      readForm: {
        distributor: []
      }
    };
  },
  created() {
    this.getDealerData();
  },
  methods: {
    handleBasicAdd() {
      this.form.basicPrices.push({
        priceType: 0,
        goodsId: this.form.goodsId,
        goodsPrice: null,
        beginTime: null,
        endTime: null,
        modMin: 1
      });
    },
    remove(d, priceType) {
      if (priceType === 0) {
        this.form.basicPrices.shift(d);
      }
      if (priceType === 1) {
        this.form.typePrices.shift(d);
      }
      if (priceType === 2) {
        this.form.singlePrices.shift(d);
      }
    },
    handleDisAdd(v, d, data) {
      this.form.singlePrices.push({
        priceType: 2,
        goodsId: this.form.goodsId,
        distributorId: v,
        distributorName: data.name,
        goodsPrice: null,
        beginTime: null,
        endTime: null,
        modMin: null,
        modMax: null
      });
    },
    handleTypeAdd(v, d, data) {
      this.form.typePrices.push({
        priceType: 1,
        distributorLeve: v,
        goodsId: this.form.goodsId,
        distributorLeveName: data.name,
        goodsPrice: null,
        beginTime: null,
        endTime: null,
        modMin: null,
        modMax: null
      });
    },
    getName(val) {
      let t = "";
      this.disLevel.forEach(i => {
        if (i.id === val) {
          t = i.name;
        }
      });
      return t;
    },
    cancel() {
      this.currentTab = "basic";
      this.form.priceType = 0;
      (this.form.basicPrices = []),
        (this.form.typePrices = []),
        (this.form.singlePrices = []);
    },
    validateForm() {
      this.$refs.basicForm.validate(valid => {
        if (valid) {
          this.$refs.typeForm.validate(valid => {
            if (valid) {
              this.$refs.singleForm.validate(valid => {
                if (valid) {
                  //save data
                  this.save();
                } else {
                  this.currentTab = "third";
                  return false;
                }
              });
            } else {
              this.currentTab = "second";
              return false;
            }
          });
        } else {
          this.currentTab = "basic";
          return false;
        }
      });
    },
    handleSave() {
      this.validateForm();
    },
    save() {
      let o = [];
      o = o.concat(this.form.basicPrices);
      o = o.concat(this.form.typePrices);
      o = o.concat(this.form.singlePrices);
      o.forEach(item => {
        if (item._dateRange && item._dateRange.length == 2) {
          item.beginTime = item._dateRange[0];
          item.endTime = item._dateRange[1];
        }
      });
      if (o.length === 0) return;
      this.saving = true;
      this.$confirm("确认调价, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          add(o)
            .then(res => {
              this.$message({
                type: "success",
                message: "调价申请成功!"
              });
              this.saving = false;
              this.$parent.init();
              this.dialog = false;
            })
            .catch(err => {
              this.saving = false;
              console.log(err.response.data.message);
            });
        })
        .catch(() => {
          this.saving = false;
          this.$message({
            type: "info",
            message: "已取消调价申请"
          });
        });
    },

    // 获取经销商类型数据
    getDealerData(retailPrice, goodsId, data) {
      this.form.goodsId = goodsId;
      this.form.goodsPrice = retailPrice;
      data &&
        data.forEach(r => {
          r._dateRange = [r.beginTime, r.endTime];
          if (r.priceType === 0) {
            this.form.basicPrices.push(r);
          }
          if (r.priceType === 1) {
            this.form.typePrices.push(r);
          }
          if (r.priceType === 2) {
            this.form.singlePrices.push(r);
          }
        });
      request({
        url: "api/distributorLeve/list",
        method: "get",
        params: {
          page: 0,
          size: 1000
        }
      })
        .then(res => {
          if (res) {
            this.disLevel = res;
          }
        })
        .catch(err => {});
    }
  }
};
</script>
